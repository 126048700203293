import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProjectCard from "./ProjectCard";

class ProjInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: 0,
                    title: 'E-Commerce Mall Store',
                    subTitle: 'Java ',
                    type: 'Full Stack',
                    link: 'https://github.com/KhalidAlghamdi8/MallStore',

                },
                {
                    id: 1,
                    title: 'Data Science Project',
                    subTitle: 'SAP Young Professional Program',
                    type: 'Data Science',
                    link: 'https://github.com/KhalidAlghamdi8/SAP-Data-Science-project/blob/main/DataScience.pdf',

                },
                {
                    id: 2,
                    title: 'Real-time Face Recognition using LBP algorithm',
                    subTitle: 'Final Master Project',
                    type: 'Machine Learning',
                    link: 'https://github.com/KhalidAlghamdi8/Final-MS-proj',

                },
                {
                    id: 3,
                    title: 'Research Paper',
                    subTitle: 'Final Master Project',
                    type: 'Machine Learning',
                    link: 'https://github.com/KhalidAlghamdi8/Final-MS-proj/blob/main/Research-Papaer.pdf',
                },
                {
                    id: 4,
                    title: 'DVDLibrary',
                    subTitle: 'Java',
                    type: 'Full Stack',
                    link: 'https://github.com/KhalidAlghamdi8/DVDLibrary',
                },
                {
                    id: 5,
                    title: 'Portfolio Website ',
                    subTitle: 'React & Material UI',
                    link: 'https://github.com/KhalidAlghamdi8/portfolio',
                },
                {
                    id: 6,
                    title: 'Back End Server App Development',
                    subTitle: 'Java Back-End',
                    link: 'https://github.com/KhalidAlghamdi8/Back-end-server-app-development-java',

                },
                {
                    id: 7,
                    title: 'Deep Features-For-Image-Retrieval-Assignment',
                    subTitle: 'ML',
                    link: 'https://github.com/KhalidAlghamdi8/Deep-features-for-image-retrieval-assignment',

                },
                {
                    id: 8,
                    title: 'Recommender Systems',
                    subTitle: 'ML',
                    link: 'https://github.com/KhalidAlghamdi8/Recommender-systems',

                },
                {
                    id: 9,
                    title: 'Algorithms for-retrieval-and-measuring-similarity-of-documents',
                    subTitle: 'ML',
                    link: 'https://github.com/KhalidAlghamdi8/Algorithms-for-retrieval-and-measuring-similarity-of-documents',

                },
                {
                    id: 10,
                    title: 'Analyzing the-sentiment-of-reviews-A-case-study-in-classification',
                    subTitle: 'ML',
                    link: 'https://github.com/KhalidAlghamdi8/Analyzing-the-sentiment-of-reviews-A-case-study-in-classification',

                },
                                {
                    id: 11,
                    title: 'Matrix Operations Calculator: 1. Addition\n' +
                        '2. Subtraction 3. Multiply\n' +
                        '4. Transpose',
                    subTitle: 'C++',
                    link: 'https://github.com/KhalidAlghamdi8/Matrix-Operations-Calculator',

                },
            ]
        }
    }


    makeItems = (items) => {
        return items.map(item => {
            return <ProjectCard item={item} key={item.id}/>
        })
    }

    render() {
        return (
            <Container fluid={true}>
                <Row className="justify-content-around">
                    {this.makeItems(this.state.items)}
                </Row>
            </Container>
        );
    }

}

export default ProjInfo;
