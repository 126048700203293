import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';

import 'bootstrap/dist/css/bootstrap.css'
import {Navbar, Nav} from "reactstrap";
import './Nav.css';


import HomePage from './pages/HomePage';
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ProjectsPage from "./pages/ProjectsPage";


class App extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            title: 'Khalid Alghamdi',
            headerLinks: [
                {title: 'Home', path: '/'},
                {title: 'About', path: '/about'},
                {title: 'Projects', path: '/projects'},
                {title: 'Contact', path: '/contact'}
            ],
            home: {
                title: 'Khalid,',
                subTitle: '‏Fresh M.S. graduate in C.S\n',
            },
            about: {
                title: 'About me'
            },
            projects: {
                title: 'Projects'
            },
            contact: {
                title: 'Contact me'
            }
        }
    }

    render() {
        return (
            <Router>
                <Container  fluid={true}>
                    <Navbar className="border-bottom"  expand="lg">
                        <Navbar className="ml-auto border-bottom" bg="transparent" expand="lg">
                            <Nav>
                                <ul>
                                    <li><Link className="nav-link" to="/">Home</Link></li>
                                    <li><Link className="nav-link" to="/about">About</Link></li>
                                    <li><Link className="nav-link" to="/projects">Projects</Link></li>
                                    <li><Link className="nav-link" to="/contact">Contact</Link></li>
                                </ul>
                            </Nav>
                        </Navbar>
                    </Navbar>

                    <Route path="/" exact
                           render={() => <HomePage title={this.state.home.title} subTitle={this.state.home.subTitle}
                                                   text={this.state.home.text}/>}/>
                    <Route path="/about" exact render={() => <AboutPage title={this.state.about.title}/>}/>
                    <Route path="/projects" exact render={() => <ProjectsPage title={this.state.projects.title}/>}/>
                    <Route path="/contact" exact render={() => <ContactPage title={this.state.contact.title}/>}/>
                </Container>
            </Router>
        );
    }
}


export default App;
