import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Khalid.css';


function Hero(props) {

    return (

        <Container fluid={true}>
            <Row className="justify-content-center py-5 ">
                <Col md={8} sm={12}>
                    {props.title && <h1 className="position-static display-1 font-weight-bolder">{props.title}</h1>}
                    {props.subTitle && <h1 className="display-4 font-weight-light">{props.subTitle}</h1>}

                </Col>
            </Row>
        </Container>

    );
}

export default Hero;

