import React from 'react';
import {makeStyles,} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";
import { Typography} from "@material-ui/core";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../Khalid.css';
import Hero from "../components/Hero";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: '40px',
        boxShadow: '0 3px 5px 2px #a8abab;',
         color: 'white',
        background: ' linear-gradient(\n' +
            '0deg\n' +
            ', #78c7c1 0%, #3e8599 50%, #1b5d7f 100%);',

    },
}));

function CircularProgressWithLabel(props) {
    return (

        <Box position="relative" display="inline-flex">
            <CircularProgress variant="indeterminate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"

            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


export default function CenteredGrid(props) {
    const classes = useStyles();

    return (

        <div className={classes.root}>
            <Hero title={props.title} />
            <Grid container spacing={1}>
                <Grid style={{borderColor: "blue"}} item xs={12}>
                    <Paper className={classes.paper}>
                        <ul>
                            <h3>Khalid Alghamdi.</h3>
                            <p>I am a fresh graduate with a master's degree from California State University, majoring
                                in
                                Computer Science, concentrating in Software Engineering. skilled in data science,
                                Machine
                                learning and Programming. Experienced in deep learning, big data, through professional
                                training and studies.</p>
                            <p>SAP Certified Consultant with high analytical skills in Data visualization, Machine
                                learning
                                and
                                Computer vision applications.</p>
                        </ul>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h2 className="border-bottom">Education:</h2>

                        <strong> California State University Dominguez Hills <sup> May 2020</sup></strong>
                        <li>Master of Science in Computer Science concentrate in Software Engineering</li>
                        <strong> Morgan State University <sup>Dec 2017</sup></strong>
                        <li>Bachelor of Science in Computer Science with 2<sup>nd</sup> honor degree</li>
                        <strong>College of Telecom & Electronics <sup>July 2012</sup></strong>
                        <li>Associate degree in Network with 2<sup>nd</sup> honor degree</li>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h2 className="border-bottom">Experience</h2>
                        <Grid className="border-bottom">

                            <strong> Trainee at Tools & Solutions: JAVA Full-Stack Development <sup>Dec 2020 - Feb
                                2021</sup></strong>
                            <li>Worked with Front-end technologies, HTML, CSS, JS, Bootstrap, and jQuery.</li>
                            <li>Worked with Back-end technologies, Servlet, Java EE, JSP, JPA, and JAX.</li>
                            <li>Worked with SDLC, Linux+, and MySQL.</li>
                        </Grid>

                        <Grid className="border-bottom">
                            <strong>Trainee at SAP “Young Professional Program” <sup>Oct 2020 - Dec 2020</sup></strong>
                            <li>Worked in many scenarios in SAP Analytics Cloud, Predictive Analytics, Predictive
                                Factory and HANA DB.
                            </li>
                            <li>Professional speaker skills and design thinking in business model.</li>
                            <li>SAP sales overview.</li>
                        </Grid>

                        <strong>E-learning Apprenticeship: Saudi Digital Academy <sup>Jul 2020 - Sep 2020</sup></strong>
                        <li>Full Stack</li>
                        <li>DevOps Engineer</li>
                        <li>Cloud DevOps Engineer</li>
                        <li>Machine Learning Engineer</li>

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>


                        <Row><Col><strong className="border-bottom">Technical Summary:</strong></Col></Row>

                        <Row>
                            <Col className="border-right">Programming language:
                                <div><CircularProgressWithLabel value="90"/>C++</div>
                                <div><CircularProgressWithLabel value="60"/>Java</div>
                                <div><CircularProgressWithLabel value="40"/>JS</div>
                                <div><CircularProgressWithLabel value="40"/>C#</div>
                                <div><CircularProgressWithLabel value="70"/>Python</div>
                            </Col>

                            <Col className="border-right">Web Technologies:
                                <div><CircularProgressWithLabel value="90"/>HTML</div>
                                <div><CircularProgressWithLabel value="80"/>CSS</div>
                                <div><CircularProgressWithLabel value="60"/>Bootstrap</div>
                                <div><CircularProgressWithLabel value="40"/>React JS</div>
                                <div><CircularProgressWithLabel value="40"/>Node JS</div>
                                <div><CircularProgressWithLabel value="70"/>Java EE</div>
                            </Col>

                            <Col className="border-right">DevOps:
                                <div><CircularProgressWithLabel value="50"/>Docker</div>
                                <div><CircularProgressWithLabel value="70"/>Git & GitHub</div>
                                <div><CircularProgressWithLabel value="20"/>Jenkins</div>
                                <div><CircularProgressWithLabel value="20"/>Selenium</div>

                            </Col>
                            <Col className="border-right">Machine Learning:
                                <div><CircularProgressWithLabel value="60"/>Computer Vision</div>
                                <div><CircularProgressWithLabel value="60"/>TensorFlow</div>
                                <div><CircularProgressWithLabel value="60"/>NumPy</div>
                                <div><CircularProgressWithLabel value="60"/>Pandas</div>
                                <div><CircularProgressWithLabel value="80"/>OpenCV</div>
                                <div><CircularProgressWithLabel value="90"/>Face Recognition</div>
                            </Col>
                            <Col className="border-right">Cloud Technologies:
                                <div><CircularProgressWithLabel value="50"/>AWS</div>

                            </Col>
                        </Row>


                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <strong>Training</strong>
                        <li>Full-Stack Development <sub>355 hours</sub></li>
                        <li>Machine Learning Engineer <sub>130 hours</sub></li>
                        <li>DevOps Engineer <sub>140 hours</sub></li>
                        <li>Cloud DevOps Engineer <sub>116 hours</sub></li>
                        <li>ITIL<sup>®</sup> Foundation Certification Training</li>
                        <li>Deep Learning Specialization</li>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <strong>Certification</strong>
                        <li>SAP Analytics Cloud</li>
                        <li>SAP Predictive Analytics</li>
                        <li>CCNA Exploration: Network Fundamentals.</li>
                        <li>CCNA Exploration: Routing Protocols and Concepts.</li>
                        <li>IT Essentials: PC Hardware and Software.</li>
                    </Paper>
                </Grid>

            </Grid>
        </div>
    );
}
