import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardInfo from "./CardInfo";




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '40px',


    },
    paper: {
        padding: '40px',
        textAlign: 'center',
        alignItems: 'center',
        boxShadow: '0 3px 5px 2px #a8abab',
        color: 'white',
        width:'450px',
        background: ' linear-gradient(\n' +
            '0deg\n' +
            ', #78c7c1 0%, #3e8599 50%, #1b5d7f 100%);',

    },


}));


export default function ProjectCard(props) {
    const classes = useStyles();
    // const preventDefault = (event) => event.preventDefault();

    return (


            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <CardInfo title={props.item.title} subTitle={props.item.subTitle} link={props.item.link}/>
                </Paper>
            </div>


    );
}
