import React from 'react';
import {Button} from "@material-ui/core";


function CardInfo(props) {


    return (
        <div>
            <p className="g-card-title">{props.title} </p>
            <p className="g-card-sub-title">{props.subTitle}</p>
            <Button  variant="outlined" color="inherit" href={props.link}>View</Button>
        </div>
    );

}

export default CardInfo;

