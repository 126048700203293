import React from 'react';
import ProjInfo from "../components/ProjInfo";
import Hero from "../components/Hero";





function ProjectsPage(props) {

    return(

<div>
   <Hero title={props.title} />
    <ProjInfo />

</div>
    );

}

export default ProjectsPage;