import React from 'react';
import '../Khalid.css';


import Hero from "../components/Hero";
import '../kk.css';

import Container from "react-bootstrap/Container";
import {Button} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";


function HomePage(props) {

    return (
        <div>
        <Container>

            <Hero title={props.title} subTitle={props.subTitle} text={props.text}/>

            <div className="container1 align-bottom border-left border-bottom py-lg-5">
            Interests:
            <div id="flip" >
                <div><div>ML</div></div>
                <div><div>SWE</div></div>
                <div><div>Data Science</div></div>
            </div>

        </div>

            <div className="text-lg-right border-right py-md-5 ">
                <Button href="https://www.linkedin.com/in/khalidalghamdi08/"><LinkedInIcon/> </Button>
                <Button href="https://twitter.com/khalid55577"><TwitterIcon/> </Button>
                <Button href="https://www.instagram.com/khalid55577/"><InstagramIcon/> </Button>
                <Button href="https://github.com/KhalidAlghamdi8"><GitHubIcon/> </Button>
                <Button href="mailto:khalid.alghamdi1055@gmail.com"><EmailIcon/> </Button>
            </div>

        </Container>
        </div>

    );

}

export default HomePage;